import Container from './Container'
import Checkbox from './Checkbox'
import Switch from './Switch'
import Radio from './Radio'
import Item from './Item'

Container.Checkbox = Checkbox
Container.Switch = Switch
Container.Radio = Radio
Container.Item = Item

export default Container
