export default {
  messages: {
    isRequired: 'Campo obrigatório',
    isCpf: 'Não é um CPF válido',
    isLength: 'Precisa ter entre %s e %s caracteres',
    minLength: 'Precisa ter pelo menos %s caracteres',
    maxLength: 'Não pode ter mais de %s caracteres',
    isDate: 'Está data não é válida',
    isAge: 'É necessário ter entre %s e %s anos',
    minAge: 'É necessário ter pelo menos %s anos',
    maxAge: 'É idade máxima é %s anos'
  }
}
